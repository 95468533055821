<template>
  <div class="content">
    <p v-if="product === 'AutoCheck'">
      <span>
        If you would like to speak to an advisor regarding this AutoCheck,
        please call
        <a href="tel:0800 597 7229">0800 597 7229</a>
      </span>
      <span>&nbsp;- option 1</span>
    </p>
    <p v-if="product === 'AutoCheck'">
      If you have received a clearance letter or fax from a finance company,
      please email a scanned copy to
      <a href="mailto:trade.investigations@uk.experian.com">
        trade.investigations@uk.experian.com
      </a>
    </p>
    <p class="has-text-size-4 has-text-primary">
      Full Contact Details
      <a
        v-tippy="{ placement: 'right' }"
        :title="showFullDetails ? 'Hide' : 'Expand'"
        class="icon has-text-soft"
        @click="showFullDetails = !showFullDetails"
      >
        <i
          class="far"
          :class="[showFullDetails ? 'fa-chevron-up' : 'fa-chevron-down']"
        />
      </a>
    </p>
    <transition enter-active-class="animated fadeIn">
      <ContactFullDetails
        v-if="showFullDetails"
        :is-embedded="true"
        @collapse="showFullDetails = !showFullDetails"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ExperianContactFormFullDetails',
  components: {
    ContactFullDetails: () => import('./ContactFullDetails')
  },
  props: {
    product: {
      type: String,
      required: true
    }
  },
  data: () => ({ showFullDetails: false })
}
</script>
